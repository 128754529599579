<template>
  <v-container>
    <div v-if="step === 'BIRTHDATE'">
      <v-form v-model="valid" @submit.stop.prevent="onSubmit">
        <h3 class="pb-5">Please enter your birthday</h3>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <DateTimePickerField
              dense
              outlined
              v-model="form.user.birthDate"
              :label="$i18n.translate('Birth Date')"
              class="required"
              :rules="rules.birthDate"
              :error-messages="$error.getValidationError(errors, 'birthDate')"
              @input="$error.clearValidationError(errors, 'birthDate')"
              onlyDate
              format="MM/DD/YYYY"
              :max="today"
            ></DateTimePickerField>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="onCancel" :loading="loading"> <v-icon left>mdi-arrow-left</v-icon> Back </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn color="primary" @click="onBirthdateSubmit()" :disabled="!valid" :loading="loading">
              Next <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div v-if="step === 'PREVENT_MINOR_REGISTRATION'">
      <v-row>
        <v-col> <ComingSoon /> </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isRacerProgramSelected()">
          <v-btn @click="onBack" :loading="loading"> <v-icon left>mdi-arrow-left</v-icon> Back </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn color="secondary" :to="{ name: 'login' }"> Login </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="step === 'CHILD_INFORMATION'">
      <v-form v-model="valid" @submit.stop.prevent="onSubmit">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <h3 class="pb-5">Please enter Parent email</h3>
            <EmailField
              dense
              id="parentEmail"
              name="parentEmail"
              :label="$i18n.translate('Parent Email')"
              v-model="form.user.parentEmail"
              :rules="rules.parentEmail"
              :error-messages="$error.getValidationError(errors, 'parentEmail')"
              @input="$error.clearValidationError(errors, 'parentEmail')"
              outlined
              class="required"
            />

            <h3 class="pb-5">Please enter Rider information</h3>
            <v-text-field
              dense
              id="firstName"
              name="firstName"
              :label="$i18n.translate('Rider First Name')"
              v-model="form.user.firstName"
              :rules="rules.childFirstName"
              :error-messages="$error.getValidationError(errors, 'firstName')"
              @input="$error.clearValidationError(errors, 'firstName')"
              outlined
              class="required"
            />
            <EmailField
              dense
              id="email"
              name="email"
              :label="$i18n.translate('Rider Email')"
              v-model="form.email1"
              :rules="rules.email1"
              :error-messages="$error.getValidationError(errors, 'email1')"
              @input="$error.clearValidationError(errors, 'email1')"
              outlined
              class="required"
            />
            <GenderField
              dense
              name="gender"
              v-model="form.user.gender"
              :label="$i18n.translate('Rider Gender')"
              :rules="rules.gender"
              :error-messages="$error.getValidationError(errors, 'gender')"
              @input="$error.clearValidationError(errors, 'gender')"
              outlined
              class="required"
            ></GenderField>
            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="form.customFieldValue8"
              :customField="selectedProgram.participantCustomFields[7]"
              outlined
              class="required"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-alert type="info">
              Rider information will only be used for account creation. During parent consent, additional Rider
              information will be collected.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="isRacerProgramSelected()">
            <v-btn @click="onBack" :loading="loading"> <v-icon left>mdi-arrow-left</v-icon> Back </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn color="primary" @click="onSubmit" :disabled="!valid" :loading="loading">
              Next <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div v-if="step === 'ADULT_INFORMATION'">
      <v-form v-model="valid" @submit.stop.prevent="onSubmit">
        <h3 class="pb-8">Please enter your contact information</h3>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <v-text-field
              id="firstName"
              name="firstName"
              :label="$i18n.translate('First Name')"
              v-model="form.user.firstName"
              :rules="rules.firstName"
              :error-messages="$error.getValidationError(errors, 'firstName')"
              @input="$error.clearValidationError(errors, 'firstName')"
              class="required"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <v-text-field
              id="middleName"
              name="middleName"
              :label="$i18n.translate('Middle Name')"
              v-model="form.user.middleName"
              :rules="rules.middleName"
              :error-messages="$error.getValidationError(errors, 'middleName')"
              @input="$error.clearValidationError(errors, 'middleName')"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <v-text-field
              id="lastName"
              name="lastName"
              :label="$i18n.translate('Last Name')"
              v-model="form.user.lastName"
              :rules="rules.lastName"
              :error-messages="$error.getValidationError(errors, 'lastName')"
              @input="$error.clearValidationError(errors, 'lastName')"
              class="required"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
            <v-text-field
              dense
              name="address1"
              :label="$i18n.translate('Address Line') + ' ' + 1"
              v-model="form.address.address1"
              :rules="rules.address1"
              :error-messages="$error.getValidationError(errors, 'address1')"
              @input="$error.clearValidationError(errors, 'address1')"
              class="required"
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
            <v-text-field
              id="address2"
              name="address2"
              :label="$i18n.translate('Address Line') + ' ' + 2"
              v-model="form.address.address2"
              :rules="rules.address2"
              :error-messages="$error.getValidationError(errors, 'address2')"
              @input="$error.clearValidationError(errors, 'address2')"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <v-text-field
              id="city"
              name="city"
              :label="$i18n.translate('City')"
              v-model="form.address.city"
              :rules="rules.city"
              :error-messages="$error.getValidationError(errors, 'city')"
              @input="$error.clearValidationError(errors, 'city')"
              class="required"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <RegionField
              id="region"
              name="region"
              :label="$i18n.translate('Region')"
              :country="form.address.country"
              v-model="form.address.region"
              :rules="rules.region"
              :error-messages="$error.getValidationError(errors, 'region')"
              @input="$error.clearValidationError(errors, 'region')"
              class="required"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <PostalCodeField
              id="postalCode"
              name="postalCode"
              :label="$i18n.translate('Postal Code')"
              :country="form.address.country"
              v-model="form.address.postalCode"
              :rules="rules.postalCode"
              :error-messages="$error.getValidationError(errors, 'postalCode')"
              @input="$error.clearValidationError(errors, 'postalCode')"
              class="required"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <PhoneNumberField
              id="phoneNumber1"
              name="phoneNumber1"
              :label="$i18n.translate('Phone Number')"
              v-model="form.phoneNumber1"
              :error-messages="$error.getValidationError(errors, 'phoneNumber1')"
              @input="$error.clearValidationError(errors, 'phoneNumber1')"
              :rules="rules.phoneNumber"
              :country="form.address.country"
              class="required"
              dense
              outlined
            ></PhoneNumberField>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="py-0">
            <EmailField
              id="email"
              name="email"
              :label="$i18n.translate('Email')"
              v-model="form.email1"
              :rules="rules.email1"
              :error-messages="$error.getValidationError(errors, 'email1')"
              @input="$error.clearValidationError(errors, 'email1')"
              class="required"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <h3 class="pt-5 pb-8">Please enter additional information needed for the program</h3>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <GenderField
              dense
              name="gender"
              v-model="form.user.gender"
              :label="$i18n.translate('Gender')"
              :rules="rules.gender"
              :error-messages="$error.getValidationError(errors, 'gender')"
              @input="$error.clearValidationError(errors, 'gender')"
              class="required"
              outlined
            ></GenderField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="form.customFieldValue8"
              :customField="selectedProgram.participantCustomFields[7]"
              class="required"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="form.customFieldValue9"
              :customField="selectedProgram.participantCustomFields[8]"
              class="required"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn @click="onBack" :loading="loading"> <v-icon left>mdi-arrow-left</v-icon> Back </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn color="primary" @click="onSubmit" :disabled="!valid" :loading="loading">
              Next <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import CustomField from "@/gapp-components/components/fields/CustomField";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import GenderField from "@/gapp-components/components/fields/GenderField.vue";
import PhoneNumberField from "@/gapp-components/components/fields/PhoneNumberField.vue";
import PostalCodeField from "@/gapp-components/components/fields/PostalCodeField.vue";
import RegionField from "@/gapp-components/components/fields/RegionField.vue";
import ValidationService from "@/gapp-components/services/validation.service";
import { mapGetters } from "vuex";
import ComingSoon from "@/views/ComingSoon.vue";

export default {
  name: "BasicInformationForm",
  components: {
    EmailField,
    RegionField,
    PostalCodeField,
    PhoneNumberField,
    DateTimePickerField,
    GenderField,
    CustomField,
    ComingSoon
  },
  props: {
    title: String,
    selectedRegistration: String,
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      step: "",
      valid: false,
      today: new Date().toISOString(),
      form: {
        user: {
          firstName: "",
          middleName: "",
          lastName: "",
          email: "",
          birthDate: null,
          gender: ""
        },
        email1: "",
        participantType: {
          participantTypeKey: ""
        },
        phoneNumber1: "",
        address: {
          address1: "",
          address2: "",
          country: {
            name: "USA",
            description: "United States",
            postalCodeMask: ["#####"],
            postalCodeRegex: "^\\d{5}$",
            postalCodeName: "Zip Code",
            phoneMask: ["(###) ###-####"],
            phoneRegex: "^\\(\\d{3}\\) \\d{3}\\-\\d{4}$",
            organizationTinName: "Federal Employer Identification Number (FEIN)",
            organizationTinMask: ["##-#######"],
            organizationTinRegex: "^\\d{2}-\\d{7}$",
            participantTinName: "Social Security Number (SSN)",
            participantTinMask: ["###-##-####"],
            participantTinRegex: "\\d{3}-\\d{2}-\\d{4}",
            locale: "en_US",
            isoAlpha2: "US",
            regionName: "State",
            currencyName: "USD",
            localeLanguage: "en",
            localeLanguageIsoAlpha3: "eng",
            localeCountry: "US"
          },
          region: null,
          city: "",
          postalCode: ""
        },
        customFieldValue8: "",
        customFieldValue9: false
      },
      errors: {}
    };
  },

  mounted() {
    this.init();
  },

  computed: {
    ...mapGetters(["selectedProgram"]),
    rules() {
      return {
        firstName: [
          ValidationService.generateNotWhitespacesRule("First Name"),
          ...ValidationService.generateTextValidationRules("First Name")
        ],
        childFirstName: [
          ValidationService.generateNotWhitespacesRule("First Name"),
          ...ValidationService.generateTextValidationRules("First Name"),
          v => /^(?:(?!--|''|'’)[\p{L}''-])+$/u.test(v) || "First Name has invalid format."
        ],
        middleName: [
          ValidationService.generateNotWhitespacesRule("Middle Name"),
          ...ValidationService.generateTextValidationRules("Middle Name", 100, false)
        ],
        lastName: [
          ValidationService.generateNotWhitespacesRule("Last Name"),
          ...ValidationService.generateTextValidationRules("Last Name")
        ],
        address1: [
          ValidationService.generateNotWhitespacesRule("Address 1"),
          ...ValidationService.generateAddressValidationRules("Address 1")
        ],
        address2: [
          ValidationService.generateNotWhitespacesRule("Address 2"),
          ...ValidationService.generateAddressValidationRules("Address 2", 200, false)
        ],
        city: [
          ValidationService.generateNotWhitespacesRule("City"),
          ...ValidationService.generateTextValidationRules("City", 100, this.requireAddress)
        ],
        region: ValidationService.generateDropdownValidationRules("Region"),
        postalCode: ValidationService.generateTextValidationRules("Postal Code"),
        phoneNumber: ValidationService.generateTextValidationRules("Phone Number"),
        email1: [
          ...ValidationService.generateEmailValidationRules("Email"),
          v => v !== this.form.user.parentEmail || "Parent email and rider email cannot be the same"
        ],
        parentEmail: [
          ...ValidationService.generateEmailValidationRules("Parent Email"),
          v => v !== this.form.email1 || "Parent email and rider email cannot be the same"
        ],
        gender: ValidationService.generateDropdownValidationRules("Gender"),
        shirtSize: ValidationService.generateDropdownValidationRules("Shirt Size"),
        birthDate: [
          ...ValidationService.generateDateValidationRules("Birth Date"),
          v => this.isNotInFuture(v) || "Birthdate cannot be in the future"
        ]
      };
    }
  },

  methods: {
    isRacerProgramSelected() {
      return this.selectedRegistration == "RACER";
    },
    isSalesProgramSelected() {
      return this.selectedRegistration == "SALES";
    },
    init() {
      if (this.isRacerProgramSelected()) {
        this.changeStep("BIRTHDATE");
      } else {
        this.changeStep("ADULT_INFORMATION");
      }
    },
    changeStep(s) {
      this.step = s;
      window.scrollTo(0, 0);
    },
    onCancel() {
      this.$emit("cancel");
    },
    onBack() {
      if (this.isRacerProgramSelected()) {
        this.changeStep("BIRTHDATE");
      } else {
        this.onCancel();
      }
    },
    onBirthdateSubmit() {
      let isUnder18 = this.$kawasaki.isUnder18(this.form.user.birthDate);
      let allowMinorRegistration = this.selectedProgram?.allowMinorRegistration;

      if (isUnder18 && !allowMinorRegistration) {
        this.changeStep("PREVENT_MINOR_REGISTRATION");
      } else if (isUnder18 && allowMinorRegistration) {
        this.changeStep("CHILD_INFORMATION");
      } else {
        this.changeStep("ADULT_INFORMATION");
      }
    },
    clearError(field) {
      this.$error.clearValidationError(this.errors, field);
    },
    onSubmit() {
      this.$emit("submit", this.form);
    },
    isNotInFuture(date) {
      if (!date) return true;
      const selectedDate = new Date(date);
      const today = new Date();
      return selectedDate <= today;
    }
  }
};
</script>
