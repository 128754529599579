<template>
  <v-card :class="selectedRegistration ? 'glow' : ''" :loading="loading">
    <v-img v-if="isRacerProgramSelected()" height="200" src="../../assets/card-bg-only-racer.jpg">
      <v-card-title class="white--text">
        <h3 class="pa-2">{{ title }}</h3>
      </v-card-title>
      <p class="pl-10 pr-10 white--text">
        {{ instruction }}
      </p>
    </v-img>

    <v-img v-else-if="isSalesProgramSelected()" height="200" src="../../assets/card-bg-only-salesperson.jpg">
      <v-card-title class="white--text">
        <h3 class="pa-2">{{ title }}</h3>
      </v-card-title>
      <p class="pl-10 pr-10 white--text">
        {{ instruction }}
      </p>
    </v-img>

    <v-toolbar v-else flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :to="{ name: 'login' }">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Cancel") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <ApiError :errors="errors"></ApiError>

      <!-- show dialog based on dialogType -->
      <v-dialog v-model="showDialog" overlay max-width="600px" transition="dialog-transition">
        <v-card>
          <v-card-title class="headline">
            This email is already registered
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="cancelLogin">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Close") }}</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <v-divider></v-divider>
            <div class="mt-3">
              <p v-if="dialogType === 'confirmation'">
                We already have an account with the email address you attempted to register with. Please login or go
                through the forgot password process to access your account.
              </p>
              <p v-else-if="dialogType === 'rejected'">
                Your registration has been cancelled for the following reason:
              </p>
              <p v-if="dialogType === 'rejected'" class="font-weight-bold">
                {{ cancelledReason }}
              </p>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="proceedToLogin">Back to Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Program Selection -->
      <div v-if="step === 'PROGRAM_SELECTION'">
        <ProgramSelection @selected="onKawasakiProgramSelected" />
      </div>

      <!-- Basic Info Form -->
      <div v-if="step === 'BASIC_INFORMATION'">
        <BasicInformationForm
          :selectedRegistration="selectedRegistration"
          @cancel="onReset"
          @submit="onBasicInformationSubmitted"
          :loading="loading"
        />
      </div>

      <div v-if="step === 'CHILD_COMPLETE'">
        <div v-if="isParentConsentEmailError">
          <p class="text-center pa-10">Something went wrong, you should contact to the kawasaki!!!</p>
        </div>
        <div v-else>
          <p class="text-center pa-8">
            In order to complete your registration, an email has been sent to your parent at
            <b>{{ participant.user.parentEmail }}</b
            >. Please have your parent continue your registration by following instructions in their email.
          </p>
          <div class="d-flex justify-center align-center pb-4">
            <v-btn class="secondary-btn " medium :to="{ name: 'login' }">
              Close
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import BasicInformationForm from "./BasicInformationForm.vue";
import ProgramSelection from "./ProgramSelection.vue";

export default {
  name: "Registration",
  components: {
    BasicInformationForm,
    ApiError,
    ProgramSelection
  },

  data() {
    return {
      loading: false,
      errors: {},
      step: "PROGRAM_SELECTION",
      participant: {},
      userCreated: {
        firstName: null,
        lastName: null,
        username: null,
        password: null
      },
      isParentConsentEmailError: false,
      showDialog: false,
      dialogType: null,
      cancelledReason: "",
      selectedRegistration: null
    };
  },

  methods: {
    isRacerProgramSelected() {
      return this.selectedRegistration == "RACER";
    },
    isSalesProgramSelected() {
      return this.selectedRegistration == "SALES";
    },
    onKawasakiProgramSelected(selectedRegistration) {
      this.selectedRegistration = selectedRegistration;
      this.changeStep("BASIC_INFORMATION");
    },
    changeStep(s) {
      this.step = s;
      window.scrollTo({
        top: 0,
        left: 0
      });
    },
    onReset() {
      this.changeStep("PROGRAM_SELECTION");
    },
    proceedToLogin() {
      this.showDialog = false;
      this.$router.push({ name: "login" }).catch(error => {
        console.log(error);
      });
    },
    cancelLogin() {
      this.showDialog = false;
    },
    onBasicInformationSubmitted(form) {
      this.participant = form;
      return this.checkIfParticipantExists(form.email1).then(exists => {
        if (exists) {
          if (exists.status.name === "CAN") {
            this.dialogType = "rejected";
            this.cancelledReason = exists.cancelledReason || "No reason provided.";
          } else {
            this.dialogType = "confirmation";
          }
          this.showDialog = true;
        } else {
          return this.enroll();
        }
      });
    },
    checkIfParticipantExists(email) {
      return this.$api
        .get(`/api/participants/byUsername/${email}/public`)
        .then(response => {
          return response.data[0];
        })
        .catch(error => {
          console.log(error);
        });
    },
    enroll() {
      this.loading = true;

      let form = {
        programKey: process.env.VUE_APP_PROGRAM_KEY,
        clientKey: process.env.VUE_APP_CLIENT_KEY,
        participant: this.participant
      };

      // Assign appropriate participant type during enrollment
      if (this.isRacerProgramSelected()) {
        form.participant.participantType.participantTypeKey = "400";
      } else if (this.isSalesProgramSelected()) {
        form.participant.participantType.participantTypeKey = "300";
      } else {
        // TODO - add error to ApiErrors (errors) object
        this.loading = false;
        return Promise.resolve();
      }

      // lowercase and trim email
      form.participant.email1 = form.participant.email1?.trim().toLowerCase();

      // Make sure user.email and user.username is populated
      form.participant.user.email = form.participant.email1;
      form.participant.user.username = form.participant.email1;

      // Make new users 'ENR' (enrolled)
      form.participant.status = { name: "ENR" };

      // Email template for new enrollments
      form.emailTemplateParticipantForm = { emailTemplateKey: "REGISTER_SITE" };

      return this.$api
        .postWithCaptcha("/api/participants/enroll/public", form, null, "register")
        .then(({ data }) => {
          this.userCreated.participantId = data.id;
          this.userCreated.username = form.participant.email1;
          this.userCreated.firstName = form.participant.user.firstName;
          this.userCreated.lastName = form.participant.user.lastName;
          this.userCreated.email = form.participant.email1;
          this.userCreated.username = form.participant.email1;
          this.userCreated.password = data.user.transientPassword;
          this.participant.id = data.id;

          if (this.$kawasaki.isUnder18(this.participant.user.birthDate)) {
            return this.sendParentConsentEmail().then(() => {
              this.changeStep("CHILD_COMPLETE");
              return Promise.resolve();
            });
          } else {
            return this.authenticate(this.userCreated);
          }
        })
        .catch(error => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sendParentConsentEmail() {
      return this.$api
        .post(`/api/privo/consent/request/${this.participant.id}/public`, {})
        .then(() => {
          console.log("Consent request sent successfully");
          return Promise.resolve();
        })
        .catch(error => {
          console.error(error);
          this.isParentConsentEmailError = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },

    authenticate(userCreated) {
      // Set loading spinner
      this.loading = true;

      // clear existing errors
      this.$auth.logout();

      // submit login request
      return this.$auth
        .login(userCreated.username, userCreated.password)
        .then(() => {
          return this.$auth.storeAvailableParticipants();
        })
        .then(() => {
          // find participant that we just enrolled
          let participants = this.availableParticipants;
          let participant = null;
          for (var i = 0; i < participants.length; i++) {
            let p = participants[i];
            if (p.id == userCreated.participantId) {
              participant = p;
              break;
            }
          }
          if (participant == null) {
            return Promise.reject("Enrolled participant not found, please try again");
          } else {
            return this.onSelect(participant);
          }
        })
        .catch(e => {
          if (this.$api.isUnauthorized(e)) {
            return Promise.reject("Invalid username or password");
          } else if (this.$api.isCredentialsExpired(e)) {
            return Promise.reject("Your credentials have expired, please return to login page to login");
          } else {
            return Promise.reject(e);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSelect(participant) {
      return this.$auth
        .storeSelectParticipant(participant)
        .then(() => {
          if (this.isRacerProgramSelected()) {
            this.$router.push({ name: "racerJoin", query: { register: 1 } }).catch(error => {
              console.log(error);
            });
          } else if (this.isSalesProgramSelected()) {
            this.$router.push({ name: "salespersonJoin", query: { register: 1 } }).catch(error => {
              console.log(error);
            });
          } else {
            this.$router.push({ name: "dashboard" }).catch(error => {
              console.log(error);
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  computed: {
    ...mapGetters(["availableParticipants"]),
    kawasakiProgramTitle() {
      if (this.selectedRegistration) {
        if (this.isRacerProgramSelected()) {
          return "Kawasaki Racer Rewards";
        } else if (this.isSalesProgramSelected()) {
          return "Kawasaki Sales Rewards";
        }
      }
      return "";
    },
    title() {
      if (this.step === "PROGRAM_SELECTION") {
        return "Select a Rewards Program to join";
      } else if (this.step === "BASIC_INFORMATION") {
        return this.kawasakiProgramTitle;
      } else if (this.step === "CHILD_COMPLETE") {
        return this.kawasakiProgramTitle;
      }
      return "";
    },
    instruction() {
      if (this.step === "PROGRAM_SELECTION") {
        return "";
      } else if (this.step === "BASIC_INFORMATION") {
        return "Please enter some basic information to join the program.";
      } else if (this.step === "CHILD_COMPLETE") {
        return "Your registration is pending your parent's consent.";
      }
      return "";
    }
  }
};
</script>
