<template>
  <v-container>
    <v-alert type="error" v-if="!selectedProgram"
      >We are currently experiencing some technical difficulties. Please retry in a few minutes.</v-alert
    >
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card>
          <v-img height="80" src="../../assets/card-bg-only-racer.jpg">
            <v-card-title class="white--text">
              <h3 class="pa-2">Racer Rewards Program</h3>
            </v-card-title>
          </v-img>
          <v-card-text style="height: 110px">
            <div>
              The Kawasaki Racer Rewards is committed to delivering a winning tradition. The All New Kawasaki Racer
              Rewards Prepaid Card - winning the most while racing the best!
            </div>
          </v-card-text>
          <v-card-actions class="justify-center pb-5">
            <v-btn color="primary" @click="onSelectRacerProgram" :disabled="!selectedProgram">Join Program</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card>
          <v-img height="80" src="../../assets/card-bg-only-salesperson.jpg">
            <v-card-title class="white--text">
              <h3 class="pa-2">Sales Rewards Program</h3>
            </v-card-title>
          </v-img>
          <v-card-text style="height: 110px">
            <div>
              The Kawasaki Sales Rewards Program allows you to earn cash incentives for your sales after you take the
              required Kawasaki K-Elite Training Courses!
            </div>
          </v-card-text>
          <v-card-actions class="justify-center pb-5">
            <v-btn color="primary" @click="onSelectSalesProgram" :disabled="!selectedProgram">Join Program</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProgramSelection",

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  },
  methods: {
    onSelectRacerProgram() {
      this.$emit("selected", "RACER");
    },
    onSelectSalesProgram() {
      this.$emit("selected", "SALES");
    }
  }
};
</script>
